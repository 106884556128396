import { useState } from '@/lib/composables';
import { appMenus } from '@/lib/constants/layout';
import { createComponent } from '@/lib/vue';
import { LayoutState } from '@/store/modules/layout.store';
import { IMenu } from '@/typings';
import { computed } from '@vue/composition-api';

export const TemporarySidebar = createComponent({
  name: 'TemporarySidebar',
  setup(props, ctx) {
    const $open = useState(s => s.layout.temporarySidebar.open);

    const $menus = computed<IMenu[]>(() => appMenus.filter(f => !f.hidden));

    return () => (
      <v-navigation-drawer
        id="TemporarySidebar"
        app
        temporary
        absolute
        disableResizeWatcher
        value={$open.value}
        onInput={LayoutState.updateTemporary}
      >
        <v-list expand>
          {$menus.value.map((menu, i) => (
            <Menus menu={menu} key={i} />
          ))}
        </v-list>
      </v-navigation-drawer>
    );
  },
});

export default TemporarySidebar;

const Menus = createComponent<{ menu: IMenu }>({
  name: 'TemporarySidebarMenus',
  props: {
    menu: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const menu = props.menu;

      if (menu.children) {
        return (
          <v-list-group
            v-model={menu.model}
            group={menu.group}
            prepend-icon={menu.icon}
            no-action
          >
            <v-list-item-content slot="activator">
              <v-list-item-title>{menu.text}</v-list-item-title>
            </v-list-item-content>

            {menu.children.map(mapChild)}
          </v-list-group>
        );
      }

      return (
        <v-list-item exact ripple to={menu.to}>
          <v-list-item-action>
            <v-icon>{menu.icon}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{menu.text}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      );
    };
  },
});

const mapChild = (child: IMenu, idx: number) => (
  <v-list-item key={idx} exact ripple to={child.to}>
    <v-list-item-content>
      <v-list-item-title>{child.text}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
);

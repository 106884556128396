import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';

export const AccountMenuAvatar = createComponent({
  name: 'AccountMenuAvatar',
  setup(props, ctx) {
    const $user = useState(s => s.auth.user);

    const $imageUrl = useState(
      s => s.auth.user?.imagem?.thumbnailUrl || s.auth.user?.imagem?.url,
    );

    return () => (
      <v-avatar color={MyTheme.cyan800} size={34}>
        {$imageUrl.value ? (
          <img src={$imageUrl.value} alt={$user.value?.nome} />
        ) : (
          <v-icon size={32} color={MyTheme.white}>
            {MyIcons.account}
          </v-icon>
        )}
      </v-avatar>
    );
  },
});

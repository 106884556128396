import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { DialogState } from '@/store/modules/dialog.store';
import { ITsxComponent } from '@/typings';

export const MyDialog = createComponent({
  name: 'MyDialog',
  setup(props, ctx) {
    const $dialog = useState(s => s.dialog);

    function handleInput(open: boolean) {
      if (!open) {
        DialogState.cancel();
      }
    }

    return () => {
      const {
        title,
        noCloseBtn,
        width,
        fullWidth,
        component,
        params,
        open,
        toolbarBtns,
      } = $dialog.value;

      return (
        <v-dialog
          persistent={noCloseBtn}
          maxWidth={width || (fullWidth ? null : 500)}
          value={open}
          onInput={handleInput}
        >
          <v-card>
            {dialogToolbar({ title, noCloseBtn, toolbarBtns })}

            {content({ component, params })}
          </v-card>
        </v-dialog>
      );
    };
  },
});

const dialogToolbar = ({
  title,
  noCloseBtn,
  toolbarBtns,
}: {
  title: string | null;
  noCloseBtn: boolean;
  toolbarBtns: any;
}) => {
  const Btns = toolbarBtns || null;

  return (
    <v-card-title class="bg-coolGray-200">
      {title && <h1 class="text-headline text-coolGray-900">{title}</h1>}

      <v-spacer />

      {Btns && <Btns />}

      {!noCloseBtn && (
        <v-btn
          icon
          text
          color={MyTheme.coolGray900}
          onClick={DialogState.cancel}
        >
          <v-icon>{MyIcons.close}</v-icon>
        </v-btn>
      )}
    </v-card-title>
  );
};

const content = ({
  component,
  params,
}: {
  component: ITsxComponent | null;
  params: any;
}) => {
  if (!component) {
    return null;
  }

  const DialogContent = component as any;

  return (
    <div class="overflow-y-auto" style="max-height: 80vh;">
      <DialogContent params={params} />
    </div>
  );
};

import { watchLazy } from '@/lib/vue';
import { FlashMessagesState } from '@/store/modules/flashMessages/flashMessages.store';
import { computed } from '@vue/composition-api';
import { useNetwork } from 'vue-use-web';

export function useNetworkStatus() {
  const { isOnline: $isOnline } = useNetwork();

  const $isOffline = computed(() => !$isOnline.value);

  return { $isOnline, $isOffline };
}

export function watchOnline(cb: (isOnline: boolean) => void) {
  const { $isOnline } = useNetworkStatus();

  watchLazy($isOnline, cb);
}

export function watchOffline(cb: (isOffline: boolean) => void) {
  const { $isOffline } = useNetworkStatus();

  watchLazy($isOffline, cb);
}

export const checkIsOnline = () => window.navigator.onLine;
export const checkIsOffline = () => !window.navigator.onLine;

export function useAlertNetworkStatus() {
  watchOffline(isOffline =>
    isOffline
      ? FlashMessagesState.alertOffline()
      : FlashMessagesState.alertOnline(),
  );
}

import { FlashMessages } from '@/components/alerts/FlashMessages';
import { MyDialog } from '@/components/dialog/MyDialog';
import { TemporarySidebar } from '@/components/sidebars/TemporarySidebar';
import { Toolbar } from '@/components/toolbar/Toolbar';
import { useState } from '@/lib/composables';
import { useUpdateAppLeft } from '@/lib/composables/useContextSidebar';
import { useAlertNetworkStatus } from '@/lib/composables/utils/useNetworkStatus';
import { useRoute } from '@/lib/composables/utils/useRouter';
import { createComponent } from '@/lib/vue';
import { FlashMessagesState } from '@/store/modules/flashMessages/flashMessages.store';
import { AppState } from '@/store/modules/root.store';

export const AppLayout = createComponent({
  name: 'AppLayout',
  beforeRouteUpdate(to, from, next) {
    FlashMessagesState.closeAll();

    next();
  },
  setup(props, ctx) {
    const $dialogId = useState(s => s.dialog.id);

    AppState.loadingOff();

    useUpdateAppLeft(ctx);

    useAlertNetworkStatus();

    return () => (
      <div id="AppLayout" class="bg-coolGray-50">
        <TemporarySidebar />

        <Toolbar />

        <v-fade-transition>
          <router-view name="sidebar" />
        </v-fade-transition>

        <v-main>
          <div class="min-h-full px-2 mx-auto pt-3 pb-8" id="main-container">
            <div class="flex flex-col">
              <FlashMessages />

              <v-fade-transition mode="out-in">
                <router-view key={useRoute().fullPath} />
              </v-fade-transition>
            </div>
          </div>
        </v-main>

        <MyDialog key={$dialogId.value} />
      </div>
    );
  },
});

export default AppLayout;

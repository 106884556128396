import { appMenus } from '@/lib/constants/layout';
import { createComponent } from '@/lib/vue';
import { IMenu } from '@/typings';
import { computed } from '@vue/composition-api';
import { TissIcon } from '../icon/TissIcon';

export const ToolbarMenus = createComponent({
  name: 'ToolbarMenus',
  setup(props, ctx) {
    const $menus = computed<IMenu[]>(() => appMenus.filter(f => !f.hidden));

    return () => (
      <v-toolbar-items class="overflow-hidden">
        {$menus.value.map(menu => menuItem(menu))}
      </v-toolbar-items>
    );
  },
});

const menuItem = (menu: IMenu) => {
  if (!menu.children) {
    return (
      <v-btn key={menu.text} text dark exact to={menu.to}>
        <v-icon class="mr-1">{menu.icon}</v-icon>

        {menu.text}
      </v-btn>
    );
  }

  return (
    <v-menu
      key={menu.text}
      light
      nudge-width={50}
      scopedSlots={{ activator: menuActivator(menu) }}
    >
      <v-list>{menu.children.map(child => menuChild(child))}</v-list>
    </v-menu>
  );
};

const menuActivator =
  (menu: IMenu) =>
  ({ on }) =>
    (
      <v-btn text dark {...{ on }}>
        <v-icon class="mr-1">{menu.icon}</v-icon>

        {menu.text}
      </v-btn>
    );

const menuChild = (child: IMenu) => (
  <v-list-item key={child.text} exact to={child.to}>
    <v-list-item-action>
      {child.tiss ? (
        <i class="v-icon">
          <TissIcon />
        </i>
      ) : (
        <v-icon>{child.icon}</v-icon>
      )}
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>{child.text}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
);

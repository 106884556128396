import { MyIcons } from '@/lib/helpers/MyIcons';
import { AuthLoginService } from '@/lib/services';
import { router } from '@/routes';
import { Routes } from '@/routes/routes';
import { AuthState } from '@/store/modules/auth.store';
import {
  getClinica,
  getProfissionalId,
  userHasClinicas,
  userIsAdminAccount,
  userIsAdminClinica,
  userIsProfissionalSaude,
} from '@/store/utils/auth';
import { IMenu } from '@/typings';
import { computed } from '@vue/composition-api';

export function useAccountMenus() {
  const $menus = computed<IMenu[]>(() => [
    { divider: true },
    {
      text: getClinica().nome,
      description: 'Selecionar clínica',
      icon: MyIcons.clinicaBox,
      action() {
        AuthState.setClinicaSelect(true);

        return router.push(Routes.auth.clinicas);
      },
      hidden: !userHasClinicas(),
    },
    {
      divider: true,
      hidden: !userHasClinicas(),
    },
    {
      text: 'Configurações da Conta',
      description: 'Clínicas, Usuários, Assinatura',
      icon: MyIcons.configuracaoAccount,
      to: Routes.app.configAccount.assinatura,
      hidden: !userIsAdminAccount(),
    },
    {
      text: 'Configurações da Clínica',
      description: 'Perfil da Clínica, Convênios, Finanças',
      icon: MyIcons.clinica,
      to: Routes.app.configClinica.perfilClinica,
      hidden: !userIsAdminClinica() && !userIsAdminAccount(),
    },
    {
      text:
        userIsAdminAccount() || userIsAdminClinica()
          ? 'Configurações de Profissionais'
          : 'Minhas configurações',
      description: 'Agenda, Procedimentos, Prontuário',
      icon: MyIcons.users,
      to: Routes.app.configProfissionais(getProfissionalId() || '').agenda,
      hidden:
        !userIsProfissionalSaude() &&
        !userIsAdminAccount() &&
        !userIsAdminClinica(),
    },
    { divider: true },
    {
      text: 'Sair',
      icon: MyIcons.sair,
      action: AuthLoginService.logout,
    },
  ]);

  return { $menus };
}

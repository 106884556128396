import { useState } from '@/lib/composables';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { AccountMenuAvatar } from './AccountMenuAvatar';

export const AccountMenuUserHeader = createComponent({
  name: 'AccountMenuUserHeader',
  setup(props, ctx) {
    const $user = useState(s => s.auth.user);

    return () => (
      <v-list-item to={Routes.app.meuPerfil}>
        <v-list-item-avatar>
          <AccountMenuAvatar />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-title">
            {$user.value?.nome}
          </v-list-item-title>

          <v-list-item-subtitle>Meu perfil</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    );
  },
});

import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent, modifiers } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { LayoutState } from '@/store/modules/layout.store';
import { getProfissionalId, userIsAdminAccount } from '@/store/utils/auth';
import { IMenu } from '@/typings';
import { Logo } from '../icon/Logo';
import { QuickMenu } from '../menus/QuickMenu';
import { AccountMenu } from './AccountMenu';
import { ToolbarMenus } from './ToolbarMenus';

export const Toolbar = createComponent({
  name: 'Toolbar',
  setup(props, ctx) {
    const homeRoute = Routes.app.home;

    const quickMenus = useQuickMenu();
    return () => (
      <v-app-bar dark app fixed clippedLeft color="primary">
        <v-app-bar-nav-icon
          onClick={modifiers.stop(LayoutState.openTemporary)}
          class="lg:hidden"
        />

        <v-toolbar-title class="pl-0 mr-2" style="min-width: 160px">
          <router-link to={homeRoute} class="flex">
            <Logo type="white" width={160} />
          </router-link>
        </v-toolbar-title>

        <v-spacer />

        <ToolbarMenus class="hidden lg:block" />

        <v-spacer />

        <QuickMenu
          menus={quickMenus}
          icon={MyIcons.plusCircle}
          btnClasses="mr-4"
        />

        <AccountMenu />
      </v-app-bar>
    );
  },
});

function useQuickMenu(): IMenu[] {
  return [
    {
      text: 'Novo Agendamento',
      icon: MyIcons.agendamentoNew,
      to: Routes.app.agenda(getProfissionalId() || '').agendamentos.new(),
    },
    {
      text: 'Novo Paciente',
      icon: MyIcons.pacienteNew,
      to: Routes.app.pacientes.new.index,
    },
    {
      divider: true,
      hidden: !userIsAdminAccount(),
    },
    {
      text: 'Novo Profissional de saúde',
      icon: MyIcons.profissional,
      to: Routes.app.configAccount.usuarios.newProfissional,
      hidden: !userIsAdminAccount(),
    },
    {
      text: 'Nova Recepcionista',
      icon: MyIcons.recepcionista,
      to: Routes.app.configAccount.usuarios.newRecepcionista,
      hidden: !userIsAdminAccount(),
    },
  ];
}

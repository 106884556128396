import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { mapMenuTile } from '../menus/mapMenuTile';
import { AccountMenuAvatar } from './accountMenu/AccountMenuAvatar';
import { AccountMenuUserHeader } from './accountMenu/AccountMenuUserHeader';
import { useAccountMenus } from './accountMenu/composables';

export const AccountMenu = createComponent({
  name: 'AccountMenu',
  setup(props, ctx) {
    const { $menus } = useAccountMenus();

    return () => (
      <v-menu
        bottom
        left
        light
        offset-y
        nudge-left={10}
        max-width={320}
        scopedSlots={{ activator: menuActivator }}
      >
        <v-list>
          <AccountMenuUserHeader />

          {$menus.value.map(mapMenuTile)}
        </v-list>
      </v-menu>
    );
  },
});

const menuActivator = ({ on }) => (
  <v-btn text dark class="px-1 mr-4 no-focus" ripple={false} {...{ on }}>
    <AccountMenuAvatar />

    <v-icon size={16}>{MyIcons.chevronDown}</v-icon>
  </v-btn>
);

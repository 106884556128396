import { Routes } from '@/routes/routes';
import {
  getProfissionalId,
  userIsAdminAccount,
  userIsAdminClinica,
  userIsProfissionalSaude,
} from '@/store/utils/auth';
import { MyIcons } from '../helpers/MyIcons';

import { IMenu } from '@/typings';

export const appMenus: IMenu[] = [
  {
    text: 'Visão geral',
    icon: MyIcons.dashboard,
    to: Routes.app.dashboard,
    get hidden() {
      return !userIsProfissionalSaude();
    },
  },
  {
    text: 'Agenda',
    icon: MyIcons.agenda,
    get to() {
      const profissionalId = getProfissionalId();

      return profissionalId
        ? Routes.app.agenda(profissionalId).index
        : Routes.app.dashboard;
    },
    get hidden() {
      return !getProfissionalId();
    },
  },
  {
    text: 'Pacientes',
    icon: MyIcons.pacientes,
    to: Routes.app.pacientes.index,
  },
  {
    text: 'Finanças',
    icon: MyIcons.financas,
    to: Routes.app.financas.index,
    get hidden() {
      return !userIsAdminClinica() && !userIsAdminAccount();
    },
  },
  {
    text: 'Relatórios',
    icon: MyIcons.relatorios,
    to: Routes.app.relatorio.atendimento.index,
    get hidden() {
      return (
        !userIsAdminClinica() &&
        !userIsAdminAccount() &&
        !userIsProfissionalSaude()
      );
    },
  },
  {
    text: 'Outros',
    icon: MyIcons.plus,
    group: 'outros',
    model: false,
    children: [
      {
        text: 'Contatos',
        icon: MyIcons.contatos,
        to: Routes.app.contatos.index,
      },
      {
        text: 'CID 10',
        icon: MyIcons.cid10,
        to: Routes.app.cid.index,
      },
      {
        text: 'Logs',
        icon: MyIcons.logs,
        to: Routes.app.logs.agenda,
      },
      {
        text: 'TISS',
        tiss: true,
        get to() {
          const profissionalId = getProfissionalId();

          return profissionalId
            ? Routes.app.tiss(profissionalId).lotes.index
            : Routes.app.dashboard;
        },
      },
    ],
  },
];

import { useState } from '@/lib/composables';
import { createComponent } from '@/lib/vue';
import { FlashMessagesState } from '@/store/modules/flashMessages/flashMessages.store';
import { Alert } from './Alert';

export const FlashMessages = createComponent({
  name: 'FlashMessages',
  setup(props, ctx) {
    const $alerts = useState(s => s.flashMessages.alerts);

    return () => (
      <transition-group
        name="slide-y-transition"
        tag="div"
        id="flash-messages"
        class="flex flex-col flex-initial"
      >
        {$alerts.value.map(alert => (
          <Alert
            key={alert.id}
            type={alert.type}
            show={true}
            onClose={() => FlashMessagesState.close(alert.id)}
            class="w-full"
          >
            <span domPropsInnerHTML={alert.text}></span>
          </Alert>
        ))}
      </transition-group>
    );
  },
});
